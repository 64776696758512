import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Pipe({
  name: 'sanitize',
  standalone: true
})
export class SanitizePipe implements PipeTransform {

    constructor(
        private domSanitizer:DomSanitizer
    ) {

    }

    /**
     * @param args 
     * {{v | sanitize: 'url' }}
     */
    transform(v: string, args: any | null = null) : SafeHtml {
        if(args == 'url') {
            return this.domSanitizer.bypassSecurityTrustResourceUrl(v);
        } else if(args == 'script') {
            return this.domSanitizer.bypassSecurityTrustScript(v);
        } else if(args == 'nl2br') {
            return v.toString().replace(/\n/g, '<br />');
        } else {
            return this.domSanitizer.bypassSecurityTrustHtml(v);
        }
    }

}
